import { useQuery } from "@tanstack/react-query";
import { APIClient } from "../services/apiClient";

export interface Faq {
  id: number;
  category: string;
  question: string;
  answers: string;
}

interface FaqResponse {
  success: boolean;
  message: string;
  data: Faq[];
  errors: null;
}

interface queryParams {
  category:
    | "marketplace"
    | "famaas"
    | "foodbank"
    | "micro_lending"
    | "commodity_linkage";
}

const useFaq = (queryParams: queryParams) => {
  const apiClient = new APIClient<FaqResponse>(
    `/faq?category=${queryParams?.category}`
  );
  return useQuery({
    queryKey: ["faq_markeplace", queryParams?.category],
    queryFn: apiClient.get,
  });
};
export default useFaq;
