import { useEffect, useState } from "react";
import "./progress.css";

export default function ProgressStepper({ steps }: { steps: any }) {
  const step = [
    {
      id: 1,
      text: "Personal Information",
    },
    {
      id: 2,
      text: "Store Information",
    },
  ];
  const [currentStep, setCurrentStep] = useState(1);
  useEffect(() => {
    setCurrentStep(steps);
  }, [steps]);

  const totalSteps = step.length;
  const progressWidth = ((currentStep - 1) / (totalSteps - 1)) * 100 || 0; // Calculate progress width
  const progressBarStyle = {
    "--progress-width": `${progressWidth}%`,
  };

  return (
    <div className="progress__wrapper__itens">
      <div className="progress__stepper">
        <ul style={progressBarStyle as any}>
          {step?.map((item) => (
            <li
              className={
                currentStep > item?.id
                  ? "completed"
                  : currentStep === item?.id
                  ? "current"
                  : "future"
              }
            >
              <span className="circle"></span>
              <span className="change">{item?.text}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
