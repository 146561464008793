import {
  Box,
  FormControl,
  Center,
  Text,
  Flex,
  FormLabel,
  Input,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import ButtonInterface from "../../components/minors/ButtonInterface";
import { useEffect, useState } from "react";
import axios from "axios";
import ModalLayout from "../../Layouts/ModalLayout";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import useSulRenewToken from "../../react-query/hooks/useRenewToken";

interface Props {
  type: "renew_token" | "request_access";
  isSupport?: boolean;
  label?: string;
}

const RequestLink = ({ type, isSupport = true, label }: Props) => {
  const [value, setValue] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  // handle the request link api call
  const { mutate, isLoading, data } = useSulRenewToken();

  const styles = {
    background: " #FFFFFF",
    border: "1px solid #D0D5DD",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "8px",
    height: "44px",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });
  const initialValues = {
    email: "",
  };

  const handleFormSubmit = (values: any) => {
    // Handle form submission here
    mutate({
      type,
      email: values.email,
    });
  };
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  });

  useEffect(() => {
    // Check if the request has been successful and open the modal
    if (!isLoading && data) {
      onOpen();
    }
  }, [isLoading, data, onOpen]);

  // update the setValue using formik
  useEffect(() => {
    if (formik.values.email) {
      setValue(formik.values.email);
    }
  }, [formik.values.email]);

  return (
    <Box w={["100%", "100%", "480px"]} mx={"auto"}>
      <form onSubmit={formik.handleSubmit}>
        <FormControl>
          <FormLabel color="#344054" size="14px">
            Email address
          </FormLabel>
          <Input
            type="email"
            placeholder="you@gmail.com"
            border={"1px solid #D0D5DD"}
            style={styles}
            py={"1.5em"}
            name="email"
            required={true}
            onChange={formik.handleChange}
          />
          {}

          <Flex direction={"column"} my={"em"}>
            {/* @ts-ignore */}
            <ButtonInterface
              my={"1em"}
              type={"submit"}
              //@ts-ignore
              px={"3em"}
              w={["100%", "243px"]}
              mx={"auto"}
              loading={isLoading}
            >
              {label ?? "Request Unique Link"}
            </ButtonInterface>
            {isSupport && (
              <a
                href="mailto:support@gogeeper.com"
                style={{
                  color: "green",
                  textDecoration: "underline",
                  fontWeight: "500",
                }}
              >
                Contact Support
              </a>
            )}
          </Flex>
        </FormControl>
      </form>

      <ModalLayout isOpen={isOpen} onClose={onClose}>
        <Center>
          <Box>
            <Box my={"1.5em"}>
              <Center>
                <svg
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_168_25696)">
                    <path
                      d="M64 0H0V64H64V0Z"
                      fill="white"
                      fillOpacity="0.01"
                    />
                    <path
                      d="M31.9997 58.6666C39.3634 58.6666 46.0301 55.6818 50.8558 50.8561C55.6815 46.0303 58.6663 39.3636 58.6663 31.9999C58.6663 24.6362 55.6815 17.9695 50.8558 13.1437C46.0301 8.31802 39.3634 5.33325 31.9997 5.33325C24.6359 5.33325 17.9693 8.31802 13.1435 13.1437C8.31778 17.9695 5.33301 24.6362 5.33301 31.9999C5.33301 39.3636 8.31778 46.0303 13.1435 50.8561C17.9693 55.6818 24.6359 58.6666 31.9997 58.6666Z"
                      fill="#D1FADF"
                      stroke="#ECFDF3"
                      strokeWidth="4"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21.333 32L29.333 40L45.333 24"
                      stroke="#039855"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <circle
                      cx="52.6959"
                      cy="56.6965"
                      r="2"
                      transform="rotate(152.558 52.6959 56.6965)"
                      fill="#039855"
                    />
                    <circle
                      cx="57.7613"
                      cy="52.7617"
                      r="3"
                      transform="rotate(-17.4416 57.7613 52.7617)"
                      fill="#039855"
                    />
                    <circle
                      cx="60.5075"
                      cy="46.5075"
                      r="2"
                      transform="rotate(-17.4416 60.5075 46.5075)"
                      fill="#039855"
                    />
                    <circle
                      cx="3.30451"
                      cy="17.6945"
                      r="2"
                      transform="rotate(150.558 3.30451 17.6945)"
                      fill="#039855"
                    />
                    <circle
                      cx="6.16049"
                      cy="11.6561"
                      r="3"
                      transform="rotate(-19.4416 6.16049 11.6561)"
                      fill="#039855"
                    />
                    <circle
                      cx="10.7558"
                      cy="7.24004"
                      r="2"
                      transform="rotate(-19.4416 10.7558 7.24004)"
                      fill="#039855"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_168_25696">
                      <rect width="64" height="64" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Center>
            </Box>
            <Text fontWeight={"bold"} fontSize={"18px"} textAlign={"center"}>
              Request Successful
            </Text>
            <Text textAlign={"center"} my={"0.5em"}>
              A link has been sent to your email at{" "}
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {" "}
                {value}{" "}
              </span>
            </Text>
            <ButtonInterface
              onClick={(e: any) =>
                (window.location.href = "https://home.gogeeper.com/")
              }
              my={"2em"}
              w={"100%"}
            >
              Explore Go-Geeper
            </ButtonInterface>
          </Box>
        </Center>
      </ModalLayout>
    </Box>
  );
};

export default RequestLink;
