//@ts-nocheck
import {
  Box,
  Flex,
  FormLabel,
  FormControl,
  Input,
  Select,
  FormErrorMessage,
  FormErrorIcon,
} from "@chakra-ui/react";
import InputInterface from "../../components/micro/TextInput";

import { useNavigate } from "react-router-dom";
import ButtonInterface from "../../components/minors/ButtonInterface";
import SelectStates from "../../components/minors/SelectStates";

import { useContext } from "react";
import { SavedContext } from "../../context/UserContext";

import { Form, FormikProvider, useFormik } from "formik";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
//@ts-ignore
import { creatStoreSchema } from "../../validations/schema/dataSchema";
import { ResponseProps } from "../../App";
import ErrorMessageHandler from "../../components/ErrorMessageHandler";
window.Buffer = window.Buffer || require("buffer").Buffer;

const validationSchema = creatStoreSchema;

interface IndexComponentProps {
  data: ResponseProps;
}

const IndexComponent = ({ data }: IndexComponentProps) => {
  const { setFirstStep } = useContext(SavedContext);
  const navigate = useNavigate();

  const acceptUrl = window.location.href;
  const getReference = acceptUrl.split("=")[1];

  const initialValues = {
    first_name: "",
    last_name: "",
    email: data.data.type === "individual" ? data.data.email : "",
    phone_number: "",
    address: "",
    gender: "",
    stateId: "",
    lgaId: "",
    reference: getReference,
  };

  const handleFormsubmit = (values: any) => {
    window.scrollTo(0, 0);
    const { first_name, last_name, lgaId, stateId, phone_number, ...rest } =
      values;
    setFirstStep({
      firstName: first_name,
      lastName: last_name,
      lgaId: parseFloat(lgaId),
      stateId: parseFloat(stateId),
      phone: phone_number,
      ...rest,
      token: data?.data.token,
    });
    navigate("/store_info");

    // dispatch(updateInputValues(values));
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (val, { setSubmitting }) => {
      handleFormsubmit(val);
      setSubmitting(false);
    },
  });

  // const handleCaptcha = () => {
  //   console.log("handled captcha ");
  // };

  const styles = {
    fontWeight: "500",
    color: "#344054",
    fontSize: "16.2px",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  };

  const { handleChange, handleBlur } = formik;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} autoComplete={"off"} noValidate>
        <Box w={["100%", "100%", "40%"]} mx={"auto"} my={"3em"}>
          <Box>
            <Flex gap={"1em"} flexDir={["column", "row"]}>
              <FormControl>
                <FormLabel className="asterisk" sx={styles}>
                  First Name
                </FormLabel>
                <Input
                  type={"text"}
                  placeholder={"First Name"}
                  name={"first_name"}
                  border={"1px solid #EDEDED"}
                  py={"1.5em"}
                  boxShadow={"0px 1px 2px rgba(16, 24, 40, 0.05)"}
                  borderRadius={"8px"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <ErrorMessageHandler name="first_name" />
                {/* <ErrorMessage name="first_name" /> */}
              </FormControl>

              <FormControl>
                <FormLabel className="asterisk" sx={styles}>
                  Last Name
                </FormLabel>
                <Input
                  placeholder={"Last Name"}
                  name={"last_name"}
                  border={"1px solid #EDEDED"}
                  py={"1.5em"}
                  boxShadow={"0px 1px 2px rgba(16, 24, 40, 0.05)"}
                  borderRadius={"8px"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessageHandler name="last_name" />
              </FormControl>
            </Flex>

            <InputInterface
              w={"100%"}
              label={"Email (optional)"}
              placeholder={"you@gmail.com"}
              name={"email"}
              type={"email"}
              value={formik.values.email}
              onChange={formik.handleChange}
              isInvalid={formik.errors.email ? true : false}
              isError={formik.errors.email}
              isDisabled={data.data.type === "individual"}
              className="optional"
            />
            {/* 
            <InputInterface
              w={"100%"}
              label={"Phone number"}
              placeholder={"0800-000-0000"}
              name={"phone_number"}
              type={"number"}
              onChange={formik.handleChange}
              isInvalid={formik.errors.phone_number ? true : false}
              isError={formik.errors.phone_number}
            /> */}

            {/* <FormControl my={"1.5em"}>
              <FormLabel className="asterisk">Phone Number</FormLabel>
              <PhoneInput
                searchPlaceholder="search"
                inputProps={{
                  name: "phone_number",
                  required: true,
                  autoFocus: true,
                }}
                country="ng"
                value={formik.values.phone_number} // Set the value prop to the corresponding formik field
                onChange={(value) =>
                  formik.setFieldValue("phone_number", value)
                } // Update the formik field value on change
              />

              <ErrorMessageHandler name="phone_number" />
            </FormControl> */}
            <FormControl my={"1.5em"}>
              <FormLabel className="asterisk" sx={styles}>
                Phone Number
              </FormLabel>
              <Input
                placeholder={"08012345678"}
                name={"phone_number"}
                border={"1px solid #EDEDED"}
                py={"1.5em"}
                boxShadow={"0px 1px 2px rgba(16, 24, 40, 0.05)"}
                borderRadius={"8px"}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorMessageHandler name="phone_number" />
            </FormControl>

            {/* <FormControl>
            <FormLabel sx={styles} className="asterisk">
              Identification Document
            </FormLabel>
            <Select
              size={"lg"}
              my={"0.5em"}
              color={"gray"}
              onChange={formik.handleChange}
              name={"userIdentityType"}
            >
              <option>Select your identification document</option>
              {docs.map((items) => {
                return (
                  <option value={items} key={items}>
                    {items}
                  </option>
                );
              })}
            </Select>
          </FormControl>

          <UploadItem
            className={"asterisk"}
            fileLabel={"upload"}
            label={"Upload identification document"}
            name={"userIdentityDocument"}
            item={imageTile}
            onChange={(e: any) => {
              handleUpload(e);
            }}
          /> */}

            <FormControl>
              <FormLabel sx={styles} className="asterisk">
                Gender{" "}
              </FormLabel>
              <Select
                size={"lg"}
                my={"0.5em"}
                name={"gender"}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value={"select_gender"}>Select a gender</option>
                <option value={"Male"}>Male</option>
                <option value={"Female"}>Female</option>
              </Select>
              <ErrorMessageHandler name="gender" />
            </FormControl>
            <FormControl>
              <FormLabel className="asterisk" sx={styles}>
                Address
              </FormLabel>
              <Input
                type={"text"}
                placeholder={"Flat No, Street Name"}
                name={"address"}
                border={"1px solid #EDEDED"}
                py={"1.5em"}
                boxShadow={"0px 1px 2px rgba(16, 24, 40, 0.05)"}
                borderRadius={"8px"}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <ErrorMessageHandler name="address" />
            </FormControl>

            <SelectStates formik={formik} />

            {/* <Center>
            <ReCAPTCHA
              sitekey="6LfP6PwlAAAAAM-xtYwFHq-rRrjQLHOUhHdQX6ZH"
              onChange={handleCaptcha}
            />
          </Center> */}

            <ButtonInterface my={"1em"} w={"100%"} type={"submit"}>
              Continue
            </ButtonInterface>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default IndexComponent;
