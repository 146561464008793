import {
  Box,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import ErrorMessageHandler from "../ErrorMessageHandler";
const InputInterface = ({
  className,
  placeholder,
  type,
  label,
  value,
  onChange,
  name,
  isInvalid,
  isError,
  mutateData,
  ...props
}: any) => {
  return (
    <>
      <Box my={"1.5em"} {...props}>
        <FormControl isInvalid={isInvalid}>
          <FormLabel
            className={className ? className : "asterisk"}
            sx={{
              fontWeight: "500",
              color: "#344054 ",
              fontSize: "16.2px",
            }}
          >
            {label}
          </FormLabel>
          <Input
            type={type}
            placeholder={placeholder}
            value={value}
            variant={""}
            border={"1px solid #EDEDED"}
            py={"1.5em"}
            boxShadow={"0px 1px 2px rgba(16, 24, 40, 0.05)"}
            borderRadius={"8px"}
            onChange={onChange}
            onBlur={
              props.onBlur && typeof props.onBlur === "function"
                ? props.onBlur
                : undefined
            }
            readOnly={props.isDisabled}
            name={name}
          />
          {mutateData?.success && !isError && (
            <p style={{ color: "green" }}>Store name is available!</p>
          )}
          {props?.mutateError ? (
            <p style={{ color: "red" }}>
              {props?.mutateError.response.data.message}
            </p>
          ) : null}

          <ErrorMessageHandler name={name} />
        </FormControl>
      </Box>
    </>
  );
};

export default InputInterface;
