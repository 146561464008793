import React, { useEffect, useState } from "react";
import useStates from "../../react-query/hooks/useStates";
import useLGA from "../../react-query/hooks/useLGA";
import {
  Box,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { FormikProps } from "formik";
import ErrorMessageHandler from "../ErrorMessageHandler";

interface SelectStatesProps {
  formik: FormikProps<any>; // Adjust the "any" to match your form values interface
}

const SelectStates: React.FC<SelectStatesProps> = ({ formik }) => {
  const { data: stateData } = useStates(1);
  const [stateId, setStateId] = useState<number>(0);
  const [hide, show] = useState("");

  function handleState(e: React.ChangeEvent<HTMLSelectElement>) {
    const newStateId = parseInt(e.target.value);
    setStateId(newStateId);
    formik.setFieldValue("lgaId", "");
  }
  // Handle city select item
  const { data: lgaData, refetch } = useLGA(stateId);

  useEffect(() => {
    if (stateId) {
      refetch();
    }
  }, [stateId]);

  return (
    <>
      <Box my="1em">
        <FormControl>
          <FormLabel my="0.5em" className="asterisk">
            State{" "}
          </FormLabel>
          <Select
            onChange={(e) => [handleState(e), formik.handleChange(e)]}
            size="lg"
            name="stateId"
          >
            <option value="select_state">Select State</option>
            {stateData &&
              stateData.data.length > 0 &&
              stateData?.data.map((item, index) => (
                <option value={item.id} key={index}>
                  {item.name}
                </option>
              ))}
          </Select>

          {/* <FormErrorMessage>
            <>
              <FormErrorIcon />
              {formik.errors.stateId}
            </>
          </FormErrorMessage> */}
          <ErrorMessageHandler name="stateId" />
        </FormControl>

        <FormControl>
          <FormLabel className="asterisk">Local Government Area </FormLabel>
          <Select
            display={hide}
            onChange={formik.handleChange}
            size="lg"
            name="lgaId"
            key={stateId}
          >
            <option value="select_lga">Select LGA</option>
            {lgaData?.data &&
              lgaData?.data.length > 0 &&
              lgaData?.data.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
          </Select>
          <ErrorMessageHandler name="lgaId" />
        </FormControl>
      </Box>
    </>
  );
};

export default SelectStates;
