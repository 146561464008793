import { APIClient } from "../services/apiClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Response from "../response/response";
import ErrorResponse from "../response/error";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export type RenewPropsBody = {
  type: "renew_token" | "request_access";
  email: string;
};

export interface ApiResponse {
  success: boolean;
  message: string;
  errors: null;
}
const apiClient = new APIClient<ApiResponse, RenewPropsBody>(
  "/snl/make-request"
);
const response = new Response();
const errorResponse = new ErrorResponse();
export class RequestError extends Error {
  response: any; // Adjust the type based on the response structure you expect

  constructor(message: string, response: any) {
    super(message);
    this.response = response;
  }
}

const useSulRenewToken = () => {
  return useMutation<ApiResponse, RequestError, RenewPropsBody>(
    (newData: RenewPropsBody) => apiClient.post(newData),
    {
      onSuccess: (data, variable) => {
        console.log(data);
        toast.success(data?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000, // Auto-close the toast after 3 seconds
        });
        // response.registerResponse(data, variable);
      },
      onError: (error) => {
        if (error.response) {
          console.log("An error occurred:", error.response.data.message);
          toast.error(error.response.data.message || error.message);
          // errorResponse.registerError(error.response.data.message);
        }
      },
    }
  );
};
export default useSulRenewToken;
