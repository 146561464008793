import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { authActions } from "./auth.slice";
import { isValidToken, setSession } from "../../utils/jwt";

export const INITIALIZE = (): any => async (dispatch: Dispatch) => {
  try {
    const accessToken = Cookies.get("accessToken");

    if (accessToken && isValidToken(accessToken)) {
      setSession(accessToken);

      const response = await axios.get(
        "https://gogeeper.co.uk/api/v1/gogeeper/validate/user",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      dispatch(
        authActions.initialize({
          isAuthenticated: true,
          user: response.data.data["Authenticate User Data Info"],
        })
      );
    } else {
      dispatch(authActions.initialize({ isAuthenticated: false }));
    }
  } catch (e) {
    dispatch(authActions.initialize({ isAuthenticated: false }));
  }
};

export const LOG_OUT = (): any => (dispatch: Dispatch) => {
  dispatch(authActions.startLoading());
  try {
    dispatch(authActions.logout());
    window.location.href = "/";
  } catch (error: any | string) {
    dispatch(
      authActions.hasError(
        error ? error?.response?.data : "Something went wrong"
      )
    );
  }
};
