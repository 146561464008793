import ContentLayout from "../Layouts/ContentLayout";
import StoreLayout from "../Layouts/StoreLayout";
import StoreInfoComponent from "./components/storeInfoComponent";

const StoreInfo = () => {
  return (
    <>
      <StoreLayout>
        <ContentLayout
          src={
            "https://res.cloudinary.com/dhdqt4xwu/image/upload/v1681979226/gogeeper/store/Content_wkccjd.svg"
          }
          step={2}
        >
          <StoreInfoComponent />
        </ContentLayout>
      </StoreLayout>
    </>
  );
};

export default StoreInfo;
