import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";

const ModalLayout = ({ children, isOpen, onOpen, onClose }: any) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>{children}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalLayout;
