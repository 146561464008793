import { Box, Text } from "@chakra-ui/react";
import ContainerLayout from "./ContainerLayout";
import ProgressStepper from "../components/progress/ProgressStepper";

const ContentLayout = ({ children, step, src }: any) => {
  function openTawkTo(): void {
    //@ts-ignore
    if (typeof window.Tawk_API === "undefined") {
      setTimeout(openTawkTo, 500);
      return;
    }
    //@ts-ignore
    window.Tawk_API.maximize();
  }

  return (
    <>
      <ContainerLayout>
        <Box my={["5em", "8em"]}>
          <Text
            fontSize={["1.2em", "1.2em", "35px"]}
            textAlign={"center"}
            fontWeight={"bold"}
          >
            Go-Geeper Store Setup
          </Text>

          <Text
            textAlign={"center"}
            justifyContent={"center"}
            gap={"0.2em"}
            my={"1em"}
            fontSize={["16px", "18px", "20px"]}
            color={"#667085"}
          >
            Fill all the details below correctly. Contact
            <span
              onClick={() => openTawkTo()}
              style={{
                gap: "1em",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {" "}
              support
            </span>{" "}
            if you have any questions.
          </Text>

          <Box my={"5em"} w={"80%"} mx={"auto"} display={["none", "block"]}>
            {/* <MyComponent>
              <LinearStepper step={step} />
            </MyComponent> */}

            <ProgressStepper steps={step} />
          </Box>

          <Box w={["100%", "80%"]} mx={"auto"} my={"2em"}>
            {children}
          </Box>
        </Box>
      </ContainerLayout>
    </>
  );
};

export default ContentLayout;
