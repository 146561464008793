import { Box, Text } from "@chakra-ui/react";
import RequestLink from "./components/requestLink";
import ContainerLayout from "../Layouts/ContainerLayout";
import useSulRenewToken from "../react-query/hooks/useRenewToken";
import StoreLayout from "../Layouts/StoreLayout";

const ExpiredRoute = () => {
  const { mutate, isLoading } = useSulRenewToken();
  return (
    <StoreLayout>
      <ContainerLayout>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"center"}
          alignSelf={"center"}
          my={"3em"}
        >
          <Box w={["100%", "100%", "850px"]}>
            <Text fontWeight={"bold"} fontSize={["29px", "30px", "50px"]}>
              Oops! The link you're trying to access has expired
            </Text>
            <Text
              w={["100%", "100%", "650px"]}
              mx={"auto"}
              color={"#667085"}
              my={"1.5em"}
              fontSize={"lg"}
            >
              To access the page you are trying to visit. Enter your email
            </Text>
            <RequestLink type="renew_token" />
          </Box>
        </Box>
      </ContainerLayout>
    </StoreLayout>
  );
};
export default ExpiredRoute;
