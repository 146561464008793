import "./styles/global.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect, ReactNode, Suspense, lazy } from "react";
import { Header, ErrorPage, Footer, Loader } from "@gogeepernpm/storybook/lib";
import { useSelector } from "react-redux";
import { IRootReducerState } from "./redux/IRootReducer";
import { useGlobalAuth } from "./hooks/useGlobalAuth";
import StoreInfo from "./pages/storeInfo";
import { CanceledError } from "axios";
import moment from "moment";
import ExpiredRoute from "./pages/expiredRoute";
import Home from "./pages";
import NoMatchRoute from "./pages/noMatch";
import { axiosInstance } from "./react-query/services/apiClient";
import LandingPage from "./pages/LandingPage/LandingPage";
import StoreLayout from "./Layouts/StoreLayout";

export interface ResponseProps {
  success: boolean;
  message: string;
  data: {
    email: string;
    token: string;
    type: string;
  };
  errors: null;
}

function App() {
  const [routePath, setRoutePath] = useState<ReactNode>();
  const { isInitialized } = useSelector(
    (state: IRootReducerState) => state.auth
  );
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleItemClick = (index: number) =>
    activeIndex === index ? setActiveIndex(-1) : setActiveIndex(index);
  const toggleSidebar = () => setOpen(!open);

  const contact = () => window.Tawk_API.maximize();
  const { triggerLogout, triggerSignUp, triggerlogin } = useGlobalAuth();

  const { isAuthenticated } = useSelector(
    (state: IRootReducerState) => state.auth
  );

  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    const currentUrl = window.location.href;
    // Create a new URLSearchParams object with the URL
    const reference = currentUrl.split("=")[1];
    const controller = new AbortController();
    setLoading(true);
    axiosInstance
      .get<ResponseProps>(`/snl/verify-token?token=${reference}`, {
        signal: controller.signal,
      })
      .then((response) => {
        setRoutePath(<Home data={response.data} />);
        setLoading(false);
      })
      .catch((err) => {
        if (err instanceof CanceledError) return;
        err?.response.data.message === "Token is invalid"
          ? // ? setRoutePath(<NoMatchRoute />)
            setRoutePath(<LandingPage />)
          : setRoutePath(<ExpiredRoute />);
        setLoading(false);
      });
    return () => controller.abort();
  }, []);

  // if (data && !isLoading) {
  //   data.message === "Token is valid" && setRoutePath(<Home />);
  // }
  // if (error && !isLoading) {
  //   error?.response.data.message === "Token is invalid"
  //     ? setRoutePath(<NoMatchRoute />)
  //     : setRoutePath(<ExpiredRoute />);
  // }

  return (
    <div className="App">
      <Suspense fallback={<Loader loading={true} />}>
        {/* <Header
          sidebarOpen={open}
          toggleSidebarOpen={toggleSidebar}
          activeIndex={activeIndex}
          onClick={handleItemClick}
          isLoggedIn={isAuthenticated}
          triggerLogin={triggerlogin}
          triggerSignup={triggerSignUp}
          triggerLogout={triggerLogout}
        /> */}

        <Router>
          <Routes>
            <Route path="/" element={routePath} />
            {/* <Route path="/" element={<ExpiredRoute />} /> */}
            <Route path="/store_info" element={<StoreInfo />} />
            <Route
              path="*"
              element={
                <StoreLayout>
                  <ErrorPage onContact={contact} onSignUp={triggerSignUp} />
                </StoreLayout>
              }
            />
          </Routes>
        </Router>
        {/* <Footer onTalkToUsClick={contact} /> */}
      </Suspense>
    </div>
  );
}

export default App;
