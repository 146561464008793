import ContentLayout from "../Layouts/ContentLayout";
import IndexComponent from "./components/indexComponent";
import { ResponseProps } from "../App";
import Logo from "../assets/Logo.png";
import StoreLayout from "../Layouts/StoreLayout";

interface HomeProps {
  data: ResponseProps;
}
const Home = ({ data }: HomeProps) => {
  return (
    <StoreLayout>
      <ContentLayout
        src={
          "https://res.cloudinary.com/dhdqt4xwu/image/upload/v1681979030/gogeeper/store/Content_rgufwx.svg"
        }
        step={1}
      >
        <IndexComponent data={data} />
      </ContentLayout>
    </StoreLayout>
  );
};

export default Home;
