import axios, { AxiosRequestConfig } from "axios";

export const axiosInstance = axios.create({
  baseURL: "https://staging.gogeeper.co.uk/api/v1",
});

// Function to set the token in the request headers
const setAuthToken = (token: string) => {
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

class APIClient<T, K = undefined> {
  endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }
  //  get all data....
  getAll = () => {
    return axiosInstance
      .get<T[]>(this.endpoint)
      .then((response) => response.data);
  };
  //   create single data....
  post = (data: K, config?: AxiosRequestConfig) => {
    return axiosInstance
      .post<T>(this.endpoint, data, config)
      .then((response) => response.data);
  };

  //   get single item
  get = () => {
    return axiosInstance
      .get<T>(this.endpoint)
      .then((response) => response.data);
  };
}

export { APIClient, setAuthToken };
