import StoreLayout from "../../Layouts/StoreLayout";
import FAQ from "./FAQ";
import MainHeader from "./MainHeader";
import TagList from "./TagList";
export default function LandingPage() {
  return (
    <StoreLayout>
      <MainHeader />
      <FAQ />
      <TagList />
    </StoreLayout>
  );
}
