import { Button } from "@chakra-ui/react";

interface Props {
  children?: any;
  bg?: any;
  color?: any;
  w?: any;
  onClick?: any;
  loading?: any;
  onSubmit?: any;
  disabled?: any;
  my?: any;
  type?: any;
  isLoading?: boolean;
}

const ButtonInterface = ({
  children,
  w,
  bg,
  color,
  onClick,
  loading,
  onSubmit,
  disabled,
  type,
  isLoading,
  ...props
}: Props) => {
  return (
    <>
      <Button
        bg={bg ? bg : "var(--primary-color)"}
        onClick={onClick}
        color={color ? color : "#fff"}
        onSubmit={onSubmit}
        _hover={{}}
        py={"1.5em"}
        w={w}
        isLoading={loading}
        loadingText={"Please Wait..."}
        disabled={disabled}
        type={type}
        {...props}
      >
        {children}
      </Button>
    </>
  );
};

export default ButtonInterface;
