import { Heading, HeadingProps } from "@chakra-ui/react";
import { ReactNode } from "react";

export default function HeadingComponent({
  children,
  rest,
  lineHeight,
}: {
  children: ReactNode;
  rest?: HeadingProps;
  lineHeight?: string | string[];
}) {
  return (
    <Heading
      p="0"
      m="0"
      color="#101828"
      fontSize={"58px"}
      fontWeight={600}
      fontStyle={"normal"}
      lineHeight={lineHeight}
      {...rest}
    >
      {children}
    </Heading>
  );
}
