import { toast } from "react-toastify";

export const successToast = (message) => {
  toast.success(message, {
    theme: "colored",
    hideProgressBar: true,
  });
};

export const errorToast = (message) => {
  toast.error(message, {
    theme: "colored",
    hideProgressBar: true,
  });
};
