//@ts-nocheck
import {
  Box,
  Text,
  FormLabel,
  FormControl,
  useToast,
  Select,
  Center,
  useDisclosure,
  Img,
  Textarea,
  FormErrorMessage,
  FormErrorIcon,
} from "@chakra-ui/react";
import InputInterface from "../../components/micro/TextInput";
import UploadItem from "../../components/minors/UploadFile";
import { useEffect, useState } from "react";
import ButtonInterface from "../../components/minors/ButtonInterface";
import { useSelector } from "react-redux";
import axios from "axios";
import ModalLayout from "../../Layouts/ModalLayout";
import { SuccessSvg } from "../../components/micro/svgAssets";
import { toByteArray, formatBase64String } from "base64-js";
import { useContext } from "react";
import { SavedContext } from "../../context/UserContext";
import useGetCategories from "../../react-query/hooks/useGetCategories";
import useDetermineUniStore from "../../react-query/hooks/useDetermineUniStore";
import { creatStoreStep_2Schema } from "../../validations/schema/dataSchema";
import { Form, FormikProvider, useFormik } from "formik";
import { debounce } from "lodash";
import useCreateSul from "../../react-query/hooks/useCreateSul";
import ErrorMessageHandler from "../../components/ErrorMessageHandler";
window.Buffer = window.Buffer || require("buffer").Buffer;

const StoreInfoComponent = () => {
  const { firstStep } = useContext(SavedContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [values, setValues] = useState({});
  const [storeLogo, setStoreLogo] = useState();
  const [imageTitle, setImageTitle] = useState(null);
  const toast = useToast();
  const [loader, setLoader] = useState<boolean>(false);

  const acceptUrl = window.location.href;
  const { mutate, data: mutateData, error } = useDetermineUniStore();
  const {
    mutate: mutateStore,
    data: storeData,
    isLoading,
  } = useCreateSul({
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  const handleForm = (e: any) => {
    const { value, name } = e.target;
    setValues({ ...values, [name]: value });
    // debouncedCheckStoreNameAvailability(value);
  };

  //handle file select change
  const handleUpload = (e: any) => {
    const file = e.target.files[0];
    if (file && file.name) {
      setImageTitle(file.name.split(".")[0]);
      setStoreLogo(file);
    }
  };

  const [data, setData] = useState([]);

  // get all categories
  const { data: categories } = useGetCategories();
  const initialValues = {
    storeCategory: "",
    storeDescription: "",
    storeName: "",
  };

  const handleFormSubmit = (values: any) => {
    if (!storeLogo) {
      alert("please upload store banner");
      return;
    }
    if (
      storeLogo &&
      firstStep &&
      Object.values(firstStep).length > 0 &&
      values &&
      mutateData?.success
    ) {
      const { reference, ...rest } = firstStep;
      const reqBody = {
        store_name: values.storeName,
        store_description: values.storeDescription,
        store_category: parseFloat(values.storeCategory),
        ...rest,
        banner: storeLogo,
      };

      mutateStore(reqBody);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: creatStoreStep_2Schema,
    onSubmit: handleFormSubmit,
  });
  const handleBlurred = () => {
    if (values.storeName && values.storeName.length > 3) {
      mutate({ store_name: values.storeName });
    }
  };

  useEffect(() => {
    if (storeData?.success) {
      onOpen();
    }
  }, [storeData]);

  const { handleChange, handleBlur } = formik;

  return (
    <>
      <Box w={["100%", "100%", "40%"]} mx={"auto"} my={"3em"}>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} autoComplete={"off"} noValidate>
            <Box>
              <UploadItem
                fileLabel={"upload"}
                label={"Store Banner"}
                name={"store_logo"}
                onChange={(e: any) => handleUpload(e)}
                item={imageTitle}
                labelInfo={"Upload your store’s Banner"}
              />

              <InputInterface
                w={"100%"}
                label={"Store name"}
                placeholder={"e.g Abbey and sons farms"}
                name={"storeName"}
                type={"text"}
                onChange={(e) => [handleForm(e), formik.handleChange(e)]}
                onBlur={() => [handleBlurred()]}
                required={true}
                isError={formik.errors.storeName}
                mutateData={mutateData}
                mutateError={error}
              />

              {/* <InputInterface
              w={"100%"}
              h={"40%"}
              label={"Short description"}
              placeholder={"Give a short description of your store"}
              name={"storeDescription"}
              type={"text"}
              onChange={handleForm}
              required={true}
            /> */}

              <FormControl mb={"1em"}>
                <FormLabel className="asterisk">Short description</FormLabel>
                <Textarea
                  placeholder={"Give a short description of your store"}
                  name={"storeDescription"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {/* <FormErrorMessage>
                  <FormErrorIcon />
                  {formik.errors.storeDescription}
                </FormErrorMessage> */}
                <ErrorMessageHandler name="storeDescription" />
              </FormControl>

              <FormControl>
                <FormLabel className="asterisk">Store category</FormLabel>
                <Select
                  size={"lg"}
                  my={"0.5em"}
                  color={"gray"}
                  name={"storeCategory"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Select a category"
                >
                  {/* <option value={"select_a_category"} disabled>
                    Select a category
                  </option> */}

                  {categories &&
                    categories.data &&
                    categories.data?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                </Select>
                <ErrorMessageHandler name="storeCategory" />
              </FormControl>

              <ButtonInterface
                my={"1em"}
                w={"100%"}
                type={"submit"}
                loading={isLoading}
              >
                Submit
              </ButtonInterface>
            </Box>
          </Form>
        </FormikProvider>
        <ModalLayout isOpen={isOpen} onClose={onClose}>
          <Center>
            <Box>
              <Box my={"1.5em"}>
                <SuccessSvg />
              </Box>
              <Text fontWeight={"bold"} fontSize={"18px"} textAlign={"center"}>
                Thank you for setting up a store on Go-Geeper
              </Text>
              <Text textAlign={"center"} my={"0.5em"}>
                Your unique credentails has now been sent to your mail. Please
                contact support if you encounter any issues.
              </Text>
              <ButtonInterface
                onClick={(e: any) =>
                  (window.location.href = "https://home.gogeeper.com/")
                }
                my={"2em"}
                w={"100%"}
              >
                Explore Go-Geeper
              </ButtonInterface>
            </Box>
          </Center>
        </ModalLayout>
      </Box>

      {/* <Img src={userIdImage} className="userImage" /> */}
    </>
  );
};

export default StoreInfoComponent;
