import { Center } from "@chakra-ui/react";
export const SuccessSvg = () => {
  return (
    <>
      <Center>
        <svg
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_168_25696)">
            <path d="M64 0H0V64H64V0Z" fill="white" fillOpacity="0.01" />
            <path
              d="M31.9997 58.6666C39.3634 58.6666 46.0301 55.6818 50.8558 50.8561C55.6815 46.0303 58.6663 39.3636 58.6663 31.9999C58.6663 24.6362 55.6815 17.9695 50.8558 13.1437C46.0301 8.31802 39.3634 5.33325 31.9997 5.33325C24.6359 5.33325 17.9693 8.31802 13.1435 13.1437C8.31778 17.9695 5.33301 24.6362 5.33301 31.9999C5.33301 39.3636 8.31778 46.0303 13.1435 50.8561C17.9693 55.6818 24.6359 58.6666 31.9997 58.6666Z"
              fill="#D1FADF"
              stroke="#ECFDF3"
              strokeWidth="4"
              strokeLinejoin="round"
            />
            <path
              d="M21.333 32L29.333 40L45.333 24"
              stroke="#039855"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle
              cx="52.6959"
              cy="56.6965"
              r="2"
              transform="rotate(152.558 52.6959 56.6965)"
              fill="#039855"
            />
            <circle
              cx="57.7613"
              cy="52.7617"
              r="3"
              transform="rotate(-17.4416 57.7613 52.7617)"
              fill="#039855"
            />
            <circle
              cx="60.5075"
              cy="46.5075"
              r="2"
              transform="rotate(-17.4416 60.5075 46.5075)"
              fill="#039855"
            />
            <circle
              cx="3.30451"
              cy="17.6945"
              r="2"
              transform="rotate(150.558 3.30451 17.6945)"
              fill="#039855"
            />
            <circle
              cx="6.16049"
              cy="11.6561"
              r="3"
              transform="rotate(-19.4416 6.16049 11.6561)"
              fill="#039855"
            />
            <circle
              cx="10.7558"
              cy="7.24004"
              r="2"
              transform="rotate(-19.4416 10.7558 7.24004)"
              fill="#039855"
            />
          </g>
          <defs>
            <clipPath id="clip0_168_25696">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Center>
    </>
  );
};
