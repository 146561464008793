import React from "react";
import Header from "../pages/LandingPage/Header";
import { Footer } from "@gogeepernpm/storybook/lib";
interface StoreLayoutProps {
  children: React.ReactNode;
}
export default function StoreLayout({ children }: StoreLayoutProps) {
  return (
    <div>
      <Header />
      {children}
      <Footer isFooter={false} />
    </div>
  );
}
