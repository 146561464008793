import { ErrorMessage } from "formik";

export default function ErrorMessageHandler({ name }: { name: string }) {
  return (
    <ErrorMessage
      name={name}
      render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
    />
  );
}
