import { Container, Link, Stack } from "@chakra-ui/react";
import HeadingComponent from "./HeadingComponent";
import colors from "../../utils/colors";
import Cta from "../../assets/Cta.png";

export default function TagList() {
  return (
    <Stack
      p={{
        lg: "120px",
        md: "120px 40px",
        sm: "120px 40px",
        base: "120px 0px",
      }}
    >
      <Container
        maxW="1240xp"
        bg={"#2B5314"}
        // backgroundImage={`linear-gradient(to right, rgba(255,255,255,.5) 0%, #2B5314 100%), url(${Cta})`}
        p={{ lg: "64px 48px", sm: "40px", base: "40px" }}
        borderRadius={"30px"}
        display={{ lg: "flex" }}
        alignItems={{ lg: "center" }}
        justifyContent={{ lg: "space-between" }}
        sx={{
          "&": {
            position: "relative",
            overflow: "hidden",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: `url(${Cta})`,
            },
            "&::after": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(18, 96, 18, .9)",
            },
            "& > *": {
              position: "relative",
              zIndex: 2,
            },
          },
        }}
      >
        <Stack mb={{ lg: "0px", md: "16px", sm: "16px", base: "16px" }}>
          <HeadingComponent
            rest={{
              fontSize: "16px",
              color: colors["white"],
              textTransform: "uppercase",
              mb: "16px",
            }}
          >
            JOin our Beta testing community
          </HeadingComponent>
          <HeadingComponent
            rest={{
              fontSize: "36px",
              color: colors["white"],
              fontWeight: 600,
            }}
          >
            Go-Geeper: Empowering Farmers, Feeding the World.
          </HeadingComponent>
        </Stack>
        <Link
          href="mailto:contactus@gogeeper.com"
          sx={{
            bg: colors["primary"],
            p: "10px 16px",
            color: colors["white"],
            whiteSpace: "noWrap",
            "&:hover": {
              bg: colors["darkerPrimary"],
              textDecoration: "none",
            },
          }}
        >
          Join our Community
        </Link>
      </Container>
    </Stack>
  );
}
