import { Flex, Stack, Image, Container, Link } from "@chakra-ui/react";
import colors from "../../utils/colors";

export default function Header() {
  return (
    <>
      <Stack bg={colors["white"]}>
        <Container maxW={"1280px"} mt="20px">
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Link
              href="/"
              sx={{
                "&:hover": {
                  textDecoration: "none",
                },
              }}
            >
              <Image
                src="https://res.cloudinary.com/dioyo0ivz/image/upload/v1700753846/Logo_Coloured_flixxg.png"
                alt="gogeeper-logo"
              />
            </Link>

            <Stack flexDirection={"row"} gap="12px" alignItems={"center"}>
              <Link
                href="mailto:contactus@gogeeper.com"
                color={colors["textColor"]}
                fontSize="16px"
                fontStyle="normal"
                fontWeight={500}
                lineHeight="24px"
                sx={{
                  p: "10px 16px",
                  "&:hover": {
                    textDecoration: "none",
                  },
                }}
              >
                Contact Us
              </Link>
            </Stack>
          </Flex>
        </Container>
      </Stack>
    </>
  );
}
