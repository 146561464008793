import * as Yup from "yup";

export const personalInfoSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name  required")
    .min(3, "First name  too short")
    .matches(/^\S*$/, "Name can't have spaces"),

  last_name: Yup.string()
    .required("Last name  required")
    .min(3, "Last name is too short")
    .matches(/^\S*$/, "Name can't have spaces"),

  email: Yup.string().required("Email  required").email("Invalid email format"),

  phone_number: Yup.string()
    .matches(/^[0-9]{11}$/, "Phone number is invalid")
    .required("Phone number is required"),
  // .matches(/^\d{13}$/, "Phone number must be 11 digits"),
});
export const creatStoreSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name  required")
    .min(3, "First name  too short")
    .matches(/^\S*$/, "Name can't have spaces"),

  last_name: Yup.string()
    .required("Last name  required")
    .min(3, "Last name is too short")
    .matches(/^\S*$/, "Name can't have spaces"),

  // email: Yup.string().required("Email  required").email("Invalid email format"),
  email: Yup.string().email("Invalid email format"),

  phone_number: Yup.string()
    .matches(/^[0-9]{11}$/, "Phone number is invalid")
    .required("Phone number is required"),
  gender: Yup.string()
    .required("Gender is required")
    .notOneOf(["select_gender", ""], "Please select a valid gender option"),
  stateId: Yup.string()
    .required("state is required")
    .notOneOf(["select_state", ""], "Please select a valid state option"),
  lgaId: Yup.string()
    .required("lga is required")
    .notOneOf(["select_lga", ""], "Please select a valid lga option"),
  // .matches(/^\d{13}$/, "Phone number must be 11 digits"),
  address: Yup.string()
    .required("Address is required")
    .min(5, "Address is too short"),
});

export const creatStoreStep_2Schema = Yup.object().shape({
  storeCategory: Yup.string()
    .required("store category is required")
    .notOneOf(
      ["select_a_category", ""],
      "Please select a valid store category "
    ),
  storeName: Yup.string()
    .required("Store name is required")
    .min(3, "Store name is too short"),
  storeDescription: Yup.string()
    .required("Description is required")
    .min(10, "Description must be at least 10 characters long"),

  // .matches(/^\d{13}$/, "Phone number must be 11 digits"),
});
