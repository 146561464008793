import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
    name: "schemaValues",
    initialState: {
        storedValues: {},  
        userIdImage: "",
    },
    reducers: {

        updateInputValues: ( state , action ) => {
            state.storedValues = action.payload;
        }, 

        updateIdImage: (state, action) => {
            state.userIdImage = action.payload
        },
        


    }
})

export const { updateInputValues  , updateIdImage } = dataSlice.actions;
//@ts-ignore
export const updateImageAction = (data): any => async(dispatch) => {
        dispatch(updateIdImage(data))
}
export const inputValueReducers = dataSlice.reducer

