import { Box, Container, Flex, Link, Stack } from "@chakra-ui/react";
import HeadingComponent from "./HeadingComponent";
import colors from "../../utils/colors";
import CreateAccordion from "../../components/micro/AccordionInstance";
import useFaq from "../../react-query/hooks/useFaq";

export default function FAQ() {
  const { data } = useFaq({ category: "foodbank" });
  return (
    <Stack
      p={{
        lg: "120px",
        md: "120px 40px",
        sm: "120px 40px",
        base: "120px 10px",
      }}
    >
      <Container maxW="1240px">
        <Flex display={{ lg: "flex" }} gap="10px">
          <Box
            as="div"
            flex="40%"
            mb={{ lg: "0px", md: "40px", sm: "40px", base: "40px" }}
          >
            <HeadingComponent
              rest={{
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: 400,
                color: colors["primary"],
              }}
            >
              FAQs
            </HeadingComponent>
            <HeadingComponent
              rest={{
                fontSize: "40px",
                lineHeight: "48px",
                fontWeight: 700,
                color: "#0B0C02",
                m: "16px 0 32px 0",
              }}
            >
              Got Questions? We’ve Got Answers
            </HeadingComponent>
            <Link
              href="mailto:contactus@gogeeper.com"
              sx={{
                bg: colors["primary"],
                p: "10px 16px",
                color: colors["white"],

                "&:hover": {
                  bg: colors["darkerPrimary"],
                  textDecoration: "none",
                },
              }}
            >
              Reach out to us
            </Link>
          </Box>
          <Box as="div" flex="60%">
            {data?.data &&
              data?.data.map(({ question, answers }, index) => {
                return (
                  <>
                    <CreateAccordion
                      key={index}
                      header={question}
                      answers={answers}
                    />
                  </>
                );
              })}
          </Box>
        </Flex>
      </Container>
    </Stack>
  );
}
