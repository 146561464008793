const ImageItem = () => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="2" y="2" width="32" height="32" rx="16" fill="#E4FBCC" />
        <path
          d="M13.3333 24H22.6667C23.403 24 24 23.403 24 22.6667V13.3333C24 12.597 23.403 12 22.6667 12H13.3333C12.597 12 12 12.597 12 13.3333V22.6667C12 23.403 12.597 24 13.3333 24ZM13.3333 24L20.6667 16.6667L24 20M16.6667 15.6667C16.6667 16.219 16.219 16.6667 15.6667 16.6667C15.1144 16.6667 14.6667 16.219 14.6667 15.6667C14.6667 15.1144 15.1144 14.6667 15.6667 14.6667C16.219 14.6667 16.6667 15.1144 16.6667 15.6667Z"
          stroke="#4CA30D"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="2"
          y="2"
          width="32"
          height="32"
          rx="16"
          stroke="#F3FEE7"
          strokeWidth="4"
        />
      </svg>
    </>
  );
};

const FileItem = () => {
  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="2" y="2" width="32" height="32" rx="16" fill="#E4FBCC" />
        <path
          d="M18.6667 11.3335H14.0001C13.6465 11.3335 13.3073 11.474 13.0573 11.724C12.8072 11.9741 12.6667 12.3132 12.6667 12.6668V23.3335C12.6667 23.6871 12.8072 24.0263 13.0573 24.2763C13.3073 24.5264 13.6465 24.6668 14.0001 24.6668H22.0001C22.3537 24.6668 22.6928 24.5264 22.9429 24.2763C23.1929 24.0263 23.3334 23.6871 23.3334 23.3335V16.0002M18.6667 11.3335L23.3334 16.0002M18.6667 11.3335V16.0002H23.3334"
          stroke="#4CA30D"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="2"
          y="2"
          width="32"
          height="32"
          rx="16"
          stroke="#F3FEE7"
          strokeWidth="4"
        />
      </svg>
    </>
  );
};

export { FileItem, ImageItem };
