import React, { createContext, ReactNode, useState, useEffect } from "react";
import { firstProps } from "../react-query/hooks/useCreateSul";

// Function to load data from sessionStorage
const loadFromSessionStorage = (): firstProps | null => {
  const savedData = sessionStorage.getItem("firstStepData");
  return savedData ? JSON.parse(savedData) : null;
};

// Function to save data to sessionStorage
const saveToSessionStorage = (data: firstProps) => {
  sessionStorage.setItem("firstStepData", JSON.stringify(data));
};

export const SavedContext = createContext<{
  firstStep: firstProps;
  setFirstStep: React.Dispatch<React.SetStateAction<firstProps>>;
}>({ firstStep: {} as firstProps, setFirstStep: () => {} });

const SavedContextProvider = ({ children }: { children: ReactNode }) => {
  const [firstStep, setFirstStep] = useState<firstProps>(
    () => loadFromSessionStorage() || ({} as firstProps)
  );

  // Save to sessionStorage whenever the firstStep state changes
  useEffect(() => {
    saveToSessionStorage(firstStep);
  }, [firstStep]);

  return (
    <SavedContext.Provider value={{ firstStep, setFirstStep }}>
      {children}
    </SavedContext.Provider>
  );
};

export default SavedContextProvider;
