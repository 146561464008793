//@ts-nocheck
import {
  Box,
  Center,
  FormLabel,
  Text,
  Input,
  Flex,
  Progress,
  FormErrorIcon,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useState } from "react";
import { errorToast } from "../../utils/ToastMessage";
import { ImageItem, FileItem } from "../micro/microFormItem";
import UploadIcon from "../micro/uploadIcon";

//@ts-ignore
import S3FileUpload from "react-s3";

interface Props {
  label?: any;
  onChange?: () => void;
  item?: any;
  name?: any;
  className?: string;
}
const UploadItem = ({
  label,
  onChange,
  fileLabel,
  item,
  name,
  className,
  labelInfo,
  deleteItem,
}: any) => {
  const [showProgress, setShowProgress] = useState("none");
  const [hideFile, setHideFile] = useState("block");
  const [progressValue, setProgressValue] = useState(0);
  const [fileName, setFileName] = useState("");
  const [error, setError] = useState("");

  const [readItem, setReady] = useState("none");

  //state to handle file item
  const [displayFile, setDisplayFile] = useState("");

  const styles = {
    color: "#3B7C0F",
    fontWeight: "500",
  };

  //handle change
  const handleChange = (event: any) => {
    const file = event.target.files[0];

    //itemUpload(file)
    if (file && file.name) {
      setError("");
      // Check if the file size exceeds 2MB
      const maxSizeInBytes = 5 * 1024 * 1024; // 2MB in bytes
      if (file.size > maxSizeInBytes) {
        // toast({
        //   description: "Image size should not exceed 2MB.",
        //   status: "error",
        //   position: "top",
        // });
        setError("Image size should not exceed 5MB");
        return;
      }

      const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];

      // Check if the file type is among the allowed types
      if (!allowedTypes.includes(file.type)) {
        setError("Please upload a JPEG, JPG, or PNG image.");
        return;
      }

      setShowProgress("block");

      setFileName(file.name);
      // setHideFile('none');

      const reader = new FileReader();
      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          setProgressValue(Math.ceil(progress));
        }
      };
      reader.onload = () => {
        // Do something with the file contents
        console.log("FIle is ready");
        setDisplayFile("success");
        setShowProgress("none");
        setReady("block");
      };
      reader.onerror = (error) => {
        errorToast("File failed to upload");
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const removeDelet = () => {
    setShowProgress("none");
    setReady("none");
  };

  return (
    <>
      <Input
        type={"file"}
        id={fileLabel}
        display={"none"}
        accept={"image/*"}
        className={"fileInput"}
        onChange={(e: any) => {
          handleChange(e);
          onChange(e);
        }}
        name={name}
      />
      <FormLabel htmlFor={fileLabel} cursor={"pointer"}>
        <Box my={"2em"}>
          <FormLabel className={className ? className : "asterisk"}>
            {label}
          </FormLabel>

          <Flex
            display={hideFile}
            border={"1px solid #D0D5DD"}
            p={"2em"}
            borderRadius={"0.5em"}
            justifyContent={"center"}
            textAlign={"center"}
          >
            <Box>
              <Center>
                <Box my={"1em"}>
                  <UploadIcon />
                </Box>
              </Center>
              <Text color={"#98A2B3"}>
                <span style={styles}>
                  {" "}
                  {labelInfo ? labelInfo : "Click to upload"}
                </span>
              </Text>
              <Text color={"#667085"}>JPEG, JPG, or PNG (max. 800x400px)</Text>
            </Box>
          </Flex>
          {error && error != "" && <p style={{ color: "red" }}>{error}</p>}

          {/* upload progress box */}
          <Box display={showProgress}>
            <Box
              border={"1px solid var(--bg)"}
              p={"1em"}
              my={"1em"}
              borderRadius={"8px"}
            >
              <Flex gap={"1em"} justifyContent={"space-between"}>
                <Box display={"flex"} alignItems={"center"} gap={"1em"}>
                  {displayFile !== " " ? <FileItem /> : <ImageItem />}

                  <Box fontWeight={"400"} color={"#344054"}>
                    <Text>{fileName}</Text>
                    <Text color={"#667085"} display={"none"}>
                      1.0mb
                    </Text>
                  </Box>
                </Box>

                <Box onClick={removeDelet}>
                  <svg
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 4.99984H3.16667M3.16667 4.99984H16.5M3.16667 4.99984V16.6665C3.16667 17.1085 3.34226 17.5325 3.65482 17.845C3.96738 18.1576 4.39131 18.3332 4.83333 18.3332H13.1667C13.6087 18.3332 14.0326 18.1576 14.3452 17.845C14.6577 17.5325 14.8333 17.1085 14.8333 16.6665V4.99984H3.16667ZM5.66667 4.99984V3.33317C5.66667 2.89114 5.84226 2.46722 6.15482 2.15466C6.46738 1.8421 6.89131 1.6665 7.33333 1.6665H10.6667C11.1087 1.6665 11.5326 1.8421 11.8452 2.15466C12.1577 2.46722 12.3333 2.89114 12.3333 3.33317V4.99984M7.33333 9.1665V14.1665M10.6667 9.1665V14.1665"
                      stroke="#667085"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Box>
              </Flex>

              <Flex alignItems={"center"} gap={"1em"}>
                <Progress
                  my={"1em"}
                  value={progressValue}
                  role={"progressbar"}
                  colorScheme={"whatsapp"}
                  borderRadius={"15px"}
                  w={"100%"}
                />

                <Text fontWeight={"400"}>{progressValue}%</Text>
              </Flex>
            </Box>
          </Box>
        </Box>
      </FormLabel>

      {/* show ready item */}

      <Box display={readItem}>
        <Box
          border={"1px solid var(--bg)"}
          p={"1em"}
          my={"1em"}
          borderRadius={"8px"}
        >
          <Flex gap={"1em"} justifyContent={"space-between"}>
            <Box display={"flex"} alignItems={"center"} gap={"1em"}>
              {displayFile !== " " ? <FileItem /> : <ImageItem />}

              <Box fontWeight={"400"} color={"#344054"}>
                <Text>{fileName}</Text>
                <Text color={"#667085"} display={"none"}>
                  1.0mb
                </Text>
              </Box>
            </Box>

            <Box onClick={removeDelet} cursor={"pointer"}>
              <svg
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 4.99984H3.16667M3.16667 4.99984H16.5M3.16667 4.99984V16.6665C3.16667 17.1085 3.34226 17.5325 3.65482 17.845C3.96738 18.1576 4.39131 18.3332 4.83333 18.3332H13.1667C13.6087 18.3332 14.0326 18.1576 14.3452 17.845C14.6577 17.5325 14.8333 17.1085 14.8333 16.6665V4.99984H3.16667ZM5.66667 4.99984V3.33317C5.66667 2.89114 5.84226 2.46722 6.15482 2.15466C6.46738 1.8421 6.89131 1.6665 7.33333 1.6665H10.6667C11.1087 1.6665 11.5326 1.8421 11.8452 2.15466C12.1577 2.46722 12.3333 2.89114 12.3333 3.33317V4.99984M7.33333 9.1665V14.1665M10.6667 9.1665V14.1665"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Box>
          </Flex>

          <Flex alignItems={"center"} gap={"1em"}>
            <Progress
              my={"1em"}
              value={progressValue}
              role={"progressbar"}
              colorScheme={"whatsapp"}
              borderRadius={"15px"}
              w={"100%"}
            />

            <Text fontWeight={"400"}>{progressValue}%</Text>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default UploadItem;
