import { Stack, Container, Box } from "@chakra-ui/react";

import HeadingComponent from "./HeadingComponent";
import TextComponent from "./TextComponent";
import RequestLink from "../components/requestLink";

export default function MainHeader() {
  return (
    <Box pb="110px">
      <Container maxW={"1108px"} mt="120px">
        <Stack alignItems={"center"}>
          <HeadingComponent rest={{ textAlign: "center", maxWidth: "927px" }}>
            Maximise Your Reach with{" "}
            <span style={{ display: "inline-block" }}>Go-Geeper</span> Stores
          </HeadingComponent>
          <TextComponent
            rest={{
              textAlign: "center",
              maxW: "696px",
              fontSize: "18.11px",
            }}
          >
            Claim your Go-Geeper store link at no cost. Create, connect, and
            capture a broader audience today.
          </TextComponent>
          <Box mt="43.4px!important">
            <RequestLink
              type="request_access"
              isSupport={false}
              label="Request New Link"
            />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
